.app_footer {
  position: relative;
  width: 100%;
  background-color: black;

  .ocean {
    .ui.image {
      width: 100%;
    }
  }

  .main {
    width: 100%;
    padding: 64px 0 0;
    background-color: black;

    @media screen and (min-width: 767px) {
      max-width: 1200px;
      padding-top: 16px;
      padding-right: 60px;
      padding-left: 60px;
      margin: 0 auto;
    }
    &.flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .logo_inverted {
      width: 80%;

      @media screen and (min-width: 767px) {
        width: 100%;
      }

      @media screen and (max-width: 767px) {
        &.flex {
          display: flex;
          justify-content: center;
        }
      }

      .flex {
        display: flex;
      }

      .ui.image {
        width: 138px;
        height: 37px;
        display: block;
        object-fit: contain;
      }
    }

    .divider {
      width: 80%;
      padding-bottom: 16px;
      stroke: white;
      @media screen and (min-width: 767px) {
        width: 100%;
      }

      @media screen and (max-width: 767px) {
        padding-bottom: 24px;
      }

      svg {
        width: 100%;
        height: 2px;
      }
    }

    .navigation {
      display: flex;
      justify-content: center;
      width: 100%;

      @media screen and (max-width: 767px) {
        &.flex {
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
        }
      }

      .c__contents,
      .c__aboutus,
      .c__guide {
        margin: {
          right: 20px;
          left: 20px;
        }

        .ui.header.contents,
        .ui.header.guide,
        .ui.header.aboutus {
          line-height: $app-line-height-main;
        }
      }

      a {
        color: white;

        &:hover,
        &:visited,
        &:active {
          transition: {
            duration: 0.4s;
          }

          text-decoration: underline;

          @include mq_down('sm') {
            text-decoration: none;
          }
        }
      }

      .ui.header.contents {
        font-size: 16px;
        color: white;

        @media screen and (max-width: 767px) {
          width: 100%;
          text-align: center;
        }
      }

      .link {
        padding-top: 6px;

        @media screen and (max-width: 767px) {
          width: 100%;
          text-align: center;
        }
      }

      .ui.header.aboutus,
      .guide {
        font-size: 16px;
        color: white;

        @include mq_down('sm') {
          margin-top: 32px;
          text-align: center;
        }
      }

      .sns_lists {
        display: flex;
        margin-top: 2em;
        @include mq_down('sm') {
          justify-content: center;
        }

        a:first-child {
          margin-left: 0;
        }
        a {
          margin-left: 16.8px;
        }
        img {
          width: 25px;
        }
      }
    }
  }

  .copyright {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 64px;
    line-height: $app-line-height-main;
    color: white;
    background-color: black;

    &.flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media screen and (max-width: 767px) {
      padding-top: 84px;
    }
  }
}
