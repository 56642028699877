.app_explain {
  margin: #{$app-explain-mTop}px auto #{$app-explain-mBottom}px;
  text-align: center;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 #{$app-explain-wrapper-pSide}px;

    .main_title {
      height: #{$app-explain-main-title-height}px;
      font-size: #{$app-explain-main-title-height}px;
      font-weight: bold;

      @include mq_down('sm') {
        height: #{$app-explain-main-title-height-sm}px;
        font-size: #{$app-explain-size-main-title-sm}px;
        line-height: 1;
      }

      .less_than_sm {
        display: none;

        @include mq_down('sm') {
          display: inline;
        }
      }
    }

    .contents {
      max-width: #{$app-explain-max-width}px;
      .sub_title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: #{$app-explain-sub-title-height}px;
        margin: #{$app-explain-mTop}px 0 #{$app-explain-sub-title-mBottom}px;
        font-size: #{$app-explain-size-sub-title}px;
        font-weight: bold;
        color: $app-color-white;
        text-align: center;
        background-color: $primary-color;

        @include mq_down('sm') {
          height: #{$app-explain-sub-title-height-sm}px;
          font-size: #{$app-explain-size-sub-title-sm}px;
          line-height: 1;
        }
      }
      img {
        width: 100%;
      }

      .less_than_sm {
        display: none;
      }

      @include mq_down('sm') {
        .more_than_md {
          display: none;
        }
        .less_than_sm {
          display: block;
        }
      }
    }
  }
}
