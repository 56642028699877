.b__hero_image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44vw;
  background-image: url('https://vegetablesale.jp/assets/hero-top-pc.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include mq_up('xl') {
    height: 600px;
  }

  @include mq_down() {
    height: 400px;
  }

  @include mq_down('sm') {
    background-image: url('https://vegetablesale.jp/assets/hero_top_mobile.jpg');
  }

  .ui.header {
    &.text {
      line-height: $app-line-height-main;
      color: white;
      text-align: center;

      @include mq_down('xl') {
        font-size: 48px;
      }

      @include mq_down() {
        font-size: 40px;
      }

      @include mq_down('sm') {
        padding: 0 6.85%;
        font-size: 28px;
      }
    }
  }
}
