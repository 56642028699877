.app_landing {
  .ui.button {
    width: 320px;
    height: 48px;
    background-color: $primary-color;
    border-radius: 5px;

    @include hover_button_opacity();

    &:hover {
      @include mq_down('sm') {
        opacity: 1;
      }
    }
  }

  .sales_list {
    .tiny-loader {
      margin-top: 50px;
    }
  }

  .concept.flex {
    display: flex;
    justify-content: center;
    padding: 80px 0 60px;

    .ui.header {
      font-size: 32px;
      line-height: $app-line-height-main;
      text-align: center;

      @include mq_down() {
        font-size: 16px;
      }

      span.description {
        color: $app-color-black;
      }
    }
  }

  .features.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 68px;

    .app_feature_circle {
      margin: 12px 20px;

      .wrapper.flex {
        .description {
          color: $app-color-black;
        }

        .ui.header.no {
          color: $app-color-black;
        }

        .ui.header.subtitle {
          span {
            color: $app-color-black;
          }
        }
      }
    }
  }

  .fish_search_form.form {
    width: 100%;

    @include mq_down() {
      padding-bottom: 20px;
    }

    .app_fish_search_form .ui.header {
      font-size: 26px;
      line-height: $app-line-height-main;

      @include mq_down('sm') {
        padding: #{$ui-header-sm}px 0;
        font-size: #{$ui-heding-size}px;
        white-space: nowrap;
      }
    }

    .app_fish_search_form .dropdowns.flex {
      display: flex;
      margin: 0 auto;
      @include mq_down() {
        flex-wrap: wrap;
      }

      .input-field {
        &.category {
          .visible.menu.transition {
            max-height: 266px !important;
          }
        }
      }
      .ui.dropdown {
        margin: {
          bottom: 8px;
        }

        &.prefecture {
          .visible.menu.transition {
            max-height: 266px !important;
          }
        }
      }
    }

    .fluid.selection.dropdown {
      padding: #{$ui-dropdown}px;

      .icon {
        padding: 8px;
      }
    }

    .btn_fish_search {
      button {
        background-color: $primary-color !important;
        padding: #{$ui-dropdown}px 4px;
      }
    }
  }

  .fishes.flex {
    @include mq_up('lg') {
      padding: 56px;
    }
    .app_titlebar {
      padding-bottom: 32px;
      text-align: center;

      .ui.header {
        font-size: 32px;
        color: $app-color-black;
      }
    }

    .route_fishes.flex {
      @include center2center();

      padding: 0 0 #{$body-bottom}px;
      text-align: center;
    }

    .button {
      width: 320px;
      height: 48px;
      font-size: #{$app-font-size-main}px;
      color: white;
      background-color: $primary-color;
      border-radius: 5px;

      @include mq_down('sm') {
        width: 256px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .fishes.flex {
      .route_fishes.flex {
        padding: 24px 0 48px;
      }
    }
  }

  @include mq_down('md') {
    .fishes.flex {
      .route_fishes.flex {
        display: block;
        padding: 21px 16px 20px;

        .button {
          width: 100%;
        }
      }
    }

    .features.flex {
      flex-wrap: wrap;

      .app_feature_circle {
        margin-right: 5px;
        margin-bottom: 24px;
        margin-left: 5px;
      }
    }

    .concept.flex {
      padding: 56px 0 25px;

      .ui.header {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .app_fish_search_form {
      padding: 0 16px;

      .ui.header {
        padding-top: 56px;
        font-size: 20px;
      }

      .btn_fish_search.flex {
        button {
          padding: 8px;
        }

        .ui.button {
          width: 100%;
        }
      }
    }

    .app_titlebar.flex .ui.header {
      font-size: 16px;
    }

    .area_selected {
      padding-bottom: 50px;
    }
  }
}
